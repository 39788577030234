import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Chatbot from "./ChatBot";
import '../styles/aichatbot.css';


const Home = () => {
  const [isChatOpen, setIsChatOpen] = useState(false); // New state for toggling chat
  const toggleChat = () => {
    console.log("it is clicked")
    setIsChatOpen(prev => !prev);
  };
  return (
    <>
      <button className="chat-icon-button" onClick={toggleChat} aria-label="Toggle Chat">
        <FontAwesomeIcon icon={faComments} className="chat-icon" />
      </button>
      <div className={`rightSideContainer ${isChatOpen ? 'open' : ''}`}>
        <Chatbot />
      </div>
    </>
  );
}

export default Home;