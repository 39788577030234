import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../styles/chatbot.css";

const Chatbot = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([
    { type: "bot", text: "Hi, how can I help you?" },
  ]);
  const [input, setInput] = useState("");
  const [recognition, setRecognition] = useState(null);

  // Initialize Speech Recognition API
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = false;
      recognitionInstance.interimResults = false;
      recognitionInstance.lang = "en-US";
      setRecognition(recognitionInstance);
    } else {
      console.error("Speech Recognition API is not supported in this browser.");
    }
  }, []);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { type: "user", text: input }]);

      try {
        console.log("body: ", JSON.stringify({ query: input }));
        const response = await fetch(
          "https://chatbot.brainwave-labs.com/ask_gpt",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              // "Accept": "*/*"
            },
            body: JSON.stringify({ query: input }),
            //   mode: 'no-cors',
          }
        );
        console.log("response: ", response);
        if (!response) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setMessages((prev) => [
          ...prev,
          { type: "bot", text: data?.data?.answer },
        ]);
        // getVisemes(data?.data?.answer);
      } catch (error) {
        console.error("There was an error with the request:", error);
        setMessages((prev) => [
          ...prev,
          {
            type: "bot",
            text: "Sorry, there was an error processing your request.",
          },
        ]);
      }

      setInput("");
    }
  };

  const handleMic = () => {
    if (recognition) {
      recognition.start();

      recognition.onstart = () => {
        console.log("Voice recognition started. Speak now...");
      };

      recognition.onspeechend = () => {
        recognition.stop();
        console.log("Speech recognition stopped.");
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log("Transcribed text:", transcript);
        setInput(transcript); // Set the transcribed text to the input box
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setMessages((prev) => [
          ...prev,
          {
            type: "bot",
            text: "Sorry, there was an issue with voice recognition.",
          },
        ]);
      };
    } else {
      console.error("Speech recognition is not initialized.");
    }
  };

  return (
    <div id="chat-container">
      <div id="chatbox">
        <div id="chat-output">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${
                msg.type === "user" ? "user-message" : "bot-message"
              }`}
            >
              {msg.text}
            </div>
          ))}
        </div>
        <div id="chat-input-area">
          <input
            type="text"
            id="chat-input"
            placeholder="Type your message here..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
          />
          <button
            className="chat-icon-button-mic"
            onClick={handleMic}
            aria-label="Activate Microphone"
          >
            <FontAwesomeIcon icon={faMicrophone} className="mic-icon" />
          </button>
          <button id="send-button" onClick={handleSend}>
            ➤
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
